<template>
  <!--jimir3x-->
  <div >
    <!--jimir3x-->
    <loading :loading="loading" />
    <!-- <div v-if="isEmpty" class="container mt-5">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div
              class="card-body d-flex justify-content-center align-items-center"
            >
              <img src="/img/no-profile.svg" alt="" />
              <h3>No Such User Found</h3>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="!loading && !isEmpty" class="profile-header ">
      <b-container class="member-view" >
        <b-row class="profile-x-info-header">
          <b-col cols="1">
         
            <img
              :src="getAvatar(userInfo.avatar, 100)"
              style="height: 85px; width:85px;"
              class="rounded-circle mr-3"
            />
          </b-col>
          <b-col cols="6" class="pl-5">
            <h3>{{ userInfo.name }} {{ userInfo.surname }}</h3>
            <p>
              {{ userInfo.title.name }} ,
              <span>{{ userInfo.company.name }}</span>
            </p>
            <p></p>
          </b-col>
          <b-col cols="5" class="text-right pt-3">
            <div v-if="uid !== GET_USER_INFO.uid">
              <b-button
                variant="primary"
                class="w-160 p-0 mr-5 button-paddings"
                @click.prevent="startConversation(uid)"
                >{{ $t('buttons.chat') }}
                <img
                  src="/img/profile-chat-plus.svg"
                  class="img-fluid ml-50"
                  alt=""
              /></b-button>
              <b-button
                variant="primary"
                class="w-160 p-0 button-paddings"
                @click.prevent="userFollow"
                v-if="!isFollowingUser"
                >{{ $t('buttons.follow') }}
                <img
                  src="/img/profile-follow-plus.svg"
                  class="img-fluid ml-50"
                  alt=""
              /></b-button>
              <b-button
                @click.prevent="userFollow"
                v-if="isFollowingUser"
                variant="primary"
                class="w-160 p-0 button-paddings"
                >{{ $t('buttons.unfollow') }}
                <img
                  src="/img/unfollow-line.svg"
                  class="img-fluid ml-50"
                  alt=""
              /></b-button>
            </div>

            <!-- <a
              @click.prevent="startConversation(uid)"
              class="member-chat-button"
            >
            
              <a href="" class="profile-right-actions-icons mr-4">
                Chat
                <img
                  src="/img/profile-chat-plus.svg"
                  class="img-fluid"
                  style="width: 24px; height: 19px; margin-left: 88px"
                  alt=""
                />
              </a>
            </a>
            <a @click.prevent="userFollow" v-if="!isFollowingUser">
              

              <a href="" class="profile-right-actions-icons">
                Connect
                <img
                  src="/img/profile-follow-plus.svg"
                  class="img-fluid"
                  style="width: 20px; height: 20px; margin-left: 62px"
                  alt=""
                />
              </a>
            </a>

            <a @click.prevent="userFollow" v-if="isFollowingUser">
             
              <a href="" class="profile-right-actions-icons reverse-icons">
                Disconnect
                <img
                  src="/img/profile-unfollow.svg"
                  class="img-fluid"
                  style="width: 20px; height: 20px"
                  alt=""
                />
              </a>
            </a> -->
          </b-col>
        </b-row>
      </b-container>
      <section class="bg-white">
        <div class="container profile-x-lists">
          <div class="row">
            <div class="col-12">
              <ul class="profile-tabs nav">
                <li>
                  <a
                    @click.prevent="changeTab(0)"
                    href="#"
                    :class="selectTab === 0 ? 'active' : ''"
                    >{{ $t('profilex.info.title') }}</a
                  >
                </li>
                <li>
                  <a
                    @click.prevent="changeTab(1)"
                    href="#"
                    :class="selectTab === 1 ? 'active' : ''"
                    >{{ $t('profilex.requests.title') }}</a
                  >
                </li>
                <li>
                  <a
                    @click.prevent="changeTab(2)"
                    href="#"
                    :class="selectTab === 2 ? 'active' : ''"
                    >{{ $t('profilex.quotes.title') }}</a
                  >
                </li>
                <li>
                  <a
                    @click.prevent="changeTab(3)"
                    href="#"
                    :class="selectTab === 3 ? 'active' : ''"
                    >{{ $t('profilex.connections.title') }}</a
                  >
                </li>
                <li>
                  <a
                    v-if="userInfo.company.website"
                    @click.prevent="changeTab(4)"
                    href="#"
                    :class="selectTab === 4 ? 'active' : ''"
                  >
                    {{ $t('profilex.company.title') }}
                  </a>
                </li>

                <li>
                  <div>
                    <b-form-input
                      class="search-bar"
                      v-model="search"
                      placeholder=""
                      v-bind:style="{
                        visibility: [1, 2, 3].includes(selectTab)
                          ? 'visible'
                          : 'hidden',
                      }"
                    >
                    </b-form-input>

                    <svg 
                    style="width:24px;height:24px" 
                    viewBox="0 0 24 24"
                     v-bind:style="{
                        visibility: [1, 2, 3].includes(selectTab)
                          ? 'visible'
                          : 'hidden',
                      }"
                    >
                     <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                      </svg>
                    
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="container">
        <div class="row">
          <div class="col-12 d-flex justify-content-between align-items-center">
           <div class="profile-header-user-info d-flex align-items-center">
              <img
                :src="getAvatar(userInfo.avatar, 85)"
                alt=""
                class="rounded-circle mr-3"
              />
              <div>
                <div class="user-name">
                  <p class="name_surname">
                    {{ userInfo.name }} {{ userInfo.surname }}
                  </p>
                  <div>
                    <v-icon :icon="['fac', 'location']" />
                    <span class="location"
                      >{{
                        userInfo.locations.find((x) => x.default).address.city
                      }},
                      {{
                        userInfo.locations.find((x) => x.default).address
                          .country
                      }}</span
                    >
                    <span class="title">Manager</span>
                    <p class="company_name">{{ userInfo.company.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="profile-header-user-connect d-flex">
              <div
                @click.prevent="startConversation(uid)"
                class="icon cursor-pointer"
                v-b-tooltip.hover
                title="Send Message User"
              >
                <v-icon :icon="['fac', 'message']" />
              </div>
              <div
                @click.prevent="userFollow"
                v-if="!isFollowingUser"
                v-b-tooltip.hover
                title="Follow User"
                class="icon cursor-pointer"
              >
                <v-icon class="fa-rotate-180" :icon="['fas', 'plus-circle']" />
              </div>
              <div
                @click.prevent="userFollow"
                v-if="isFollowingUser"
                v-b-tooltip.hover
                title="Unfollow User"
                class="icon cursor-pointer"
              >
                <v-icon class="fa-rotate-180" :icon="['fas', 'minus-circle']" />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="!loading && !isEmpty" class="user-profile-content  ">
      <info :info="userInfo" v-if="selectTab === 0 && !loading" />
      <requests
        :info="userInfo"
        :search="search"
        v-if="selectTab === 1 && !loading"
      />
      <listings
        :info="userInfo"
        :search="search"
        v-if="selectTab === 2 && !loading"
      />
      <connections
        :info="userInfo"
        :search="search"
        v-if="selectTab === 3 && !loading"
      />
      <company :info="userInfo" v-if="selectTab === 4 && !loading" />
    </div>
  </div>
</template>

<script>
import info from '@/components/app/user/profile/info';
import requests from '@/components/app/user/profile/requests';
import listings from '@/components/app/user/profile/listings';
import Connections from '@/components/app/user/profile/connections';
import company from '@/components/app/user/profile/company.vue';
// graphql
import userInfo from '@/graphql/user/userInfo.graphql';
import isFollowing from '@/graphql/user/isFollowing.graphql';

export default {
  name: 'profile',
  components: {
    Connections,
    info,
    requests,
    listings,
    company,
  },
  data() {
    return {
      loading: true,
      selectTab: 0,
      uid: null,
      userInfo: null,
      isEmpty: false,
      isFollowingUser: false,
      search: null,
    };
  },
  watch: {
    '$route.params.id'() {
      location.reload();
    },
  },
  methods: {
    changeTab(index) {
      this.selectTab = index;
    },
    async userFollow() {
      try {
        if (this.isFollowingUser) {
          return await this.unfollowUser(this.uid);
        }
        return await this.followUser(this.uid);
      } catch (e) {}
    },
    async followUser(member_id) {
      let payload = {
        uid: member_id,
      };
      let response = await this.$store.dispatch('user/followUser', {
        vm: this,
        payload,
      });
      if (response) {
        this.isFollowingUser = true;
        return this.$bvToast.toast(this.$t("Toaster.followed"), {
          title: this.$t("Toaster.success-title"),
          variant: 'success',
          autoHideDelay:1350,
        });
      }
    },
    async unfollowUser(member_id) {
      let payload = {
        uid: member_id,
      };
      let response = await this.$store.dispatch('user/unfollowUser', {
        vm: this,
        payload,
      });
      if (response) {
        this.isFollowingUser = false;
        return this.$bvToast.toast(this.$t("Toaster.unfollowed"), {
          title: this.$t("Toaster.success-title"),
          variant: 'success',
         autoHideDelay: 1350,
        });
      }
    },
    async getUserInfo() {
      try {
        this.loading = true;
        let params = {
          uid: this.uid,
        };
        let data = await this.$apollo.query(userInfo, params);
        let response = data('**.info');
        if (!response.id) {
          this.isEmpty = true;
        }
        this.userInfo = response;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async isFollowing() {
      try {
        let data = await this.$apollo.query(isFollowing, { uid: this.uid });
        let response = data('**.isFollowing');
        this.isFollowingUser = response;
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    let id = this.$route.params.id;
    // if (id === this.GET_USER_INFO.uid) {
    //   return this.$router.push("/app/profile/settings/profile");
    // }
    this.uid = id;
    this.getUserInfo();
    this.isFollowing();
  },
};
</script>
