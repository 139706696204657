<template>
  <!--jimir3x-->
  <div class="info-content container radius shadow">
    <div class="profile-company__header shadow">
      <h3 class="profile-company__title">
        {{ company.name }}
      </h3>
    </div>
    <dl class="profile-company__info-list">
      <dt>{{ $t('profilex.company.label1') }}</dt>
      <dd class="profile-company__img-container">
        <img :src="CDN_IMAGE_PREFIX + company.logo" />
      </dd>
      <dt>{{ $t('profilex.company.label2') }}</dt>
      <dd>{{ company.website }}</dd>
      <dt>{{ $t('profilex.company.label3') }}</dt>
      <dd>
        {{ company.number_of_employees.low }} -
        {{ company.number_of_employees.high }}
      </dd>
      <dt>{{ $t('profilex.company.label4') }}</dt>
      <dd>
        {{ company.description }}
      </dd>
    </dl>

    <hr />

    <div class="profile-company__location-container">
      <h3 style="margin-top:10px;">{{ $t('profilex.company.label5') }}</h3>

      <mapbox-map
        :scrollZoom="false"
        style="height: 350px; width: 100%; margin-top: 32px"
        access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
        map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
        :zoom="7"
        :minZoom="1"
        :center="[
          $_.get(company, 'location.point.lon', 10),
          $_.get(company, 'location.point.lat', 10),
        ]"
        :maxZoom="51"
        :dragRotate="false"
        :dragPan="false"
        :keyboard="false"
        :doubleClickZoom="false"
      >
        <mapbox-navigation-control position="top-right" />
        <mapbox-marker
          :lng-lat="[
            $_.get(company, 'location.point.lon', 10),
            $_.get(company, 'location.point.lat', 10),
          ]"
        />
      </mapbox-map>
    </div>

    <div class="profile-company__gallery-container" v-if="company.media.length > 0">
      <hr />
      <h3>{{ $t('profilex.company.label6') }}</h3>

      <div class="profile-company__gallery">
        <div class="profile-company__gallery-left" v-on:click="() => onClickGalleryEl(0)">
          <img
            v-if="['jpeg', 'jpg', 'png'].includes(company.media[0].ext)"
            class="radius"
            :src="CDN_IMAGE_PREFIX + company.media[0].code + '.' + company.media[0].ext"
          />

          <video v-if="['webm', 'mp4'].includes(company.media[0].ext)" controls>
            <source :src="CDN_VIDEO_PREFIX + company.media[0].code + '.' + company.media[0].ext" />
          </video>

          <audio v-else-if="['mp3'].includes(company.media[0].ext)" controls>
            <source :src="CDN_VIDEO_PREFIX + company.media[0].code + '.' + company.media[0].ext" />
          </audio>
        </div>
        <div class="profile-company__gallery-right">
          <div 
            v-if="company.media.length > 1" 
            v-for="(item, index) in company.media.slice(1)"
            v-on:click="() => onClickGalleryEl(index + 1)"
          >
             <img
                v-if="['jpeg', 'jpg', 'png'].includes(item.ext)"
                class="radius"
                :src="CDN_IMAGE_PREFIX + item.code + '.' + item.ext"
              />

              <video v-if="['webm', 'mp4'].includes(item.ext)" controls>
                <source :src="CDN_VIDEO_PREFIX + item.code + '.' + item.ext" />
              </video>

              <audio v-else-if="['mp3'].includes(item.ext)" controls>
                <source :src="CDN_VIDEO_PREFIX + item.code + '.' + item.ext" />
              </audio>
          </div>
          <div v-if="company.media.length % 2 == 0" />
        </div>
      </div>
    </div>

    <div class="gallery-modal" v-if="modal.visible" v-on:click="onClickOverlay">
      <div class="gallery-modal__close" v-on:click="onClickOverlayCross">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>
      </div>
      <div class="gallery-modal__inner">
        <img
          v-if="['jpeg', 'jpg', 'png'].includes(modal.content.ext)"
          class="radius"
          :src="CDN_IMAGE_PREFIX + modal.content.code + '.' + modal.content.ext"
        />

        <video v-if="['webm', 'mp4'].includes(modal.content.ext)" controls>
          <source :src="CDN_VIDEO_PREFIX + modal.content.code + '.' + modal.content.ext" />
        </video>

        <audio v-else-if="['mp3'].includes(modal.content.ext)" controls>
          <source :src="CDN_VIDEO_PREFIX + modal.content.code + '.' + modal.content.ext" />
        </audio>
        
        <div v-bind:class="{'disabled' : modal.index == 0}" class="prev-button" v-on:click="onClickPrev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="#000000"
          >
            <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
            <path
              d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
            />
          </svg>
        </div>
        <div v-bind:class="{'disabled' : modal.index == company.media.length - 1}" class="next-button" v-on:click="onClickNext">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="#000000"
          >
            <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
            <path
              d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>

  <!--
  <b-container class="info-content">
    <b-row>
      <b-col cols="4">Logo:</b-col>
      <b-cols cols="8">
        <b-img height="40" :src="CDN_IMAGE_PREFIX + company.logo"></b-img>
      </b-cols>
    </b-row>
    <b-row>
      <b-col cols="4">
        <h4>company name</h4>
        <h4>company website</h4>
        <h4>description</h4>
      </b-col>
      <b-col cols="8" class="info-content-section">
        <h4>{{ company.name }}</h4>
        <h4>
          {{ company.website }}
        </h4>
        <h4>{{ company.description }}</h4>
      </b-col>
    </b-row>
    <b-row v-if="$_.size(company.media) > 0">
      <b-col cols="12">
        <div
          class="float-left"
          style="margin-bottom: 10px; margin-right:5px; padding-top: 5px"
          v-for="(media, index) in company.media"
          :key="index"
        >
          <img
            v-if="['jpeg', 'jpg', 'png'].includes(media.ext)"
            :src="CDN_IMAGE_PREFIX + media.code + '.png?size=100xauto'"
            width="100"
          />
          <video
            v-else-if="['webm', 'mp4'].includes(media.ext)"
            width="320"
            height="240"
            controls
          >
            <source :src="CDN_VIDEO_PREFIX + media.code" />
          </video>
          <audio v-else-if="['mp3'].includes(media.ext)" controls>
            <source :src="CDN_VIDEO_PREFIX + media.code" type="audio/mpeg" />
          </audio>
          <img v-else src="@/assets/img/file.png" width="100" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        {{ $_.get(company, 'location.address.city') }},
        {{ $_.get(company, 'location.address.country') }}
        <mapbox-map
          :scrollZoom="false"
          style="height: 350px; width: 100%; margin-top: 20px"
          access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
          map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
          :zoom="7"
          :minZoom="1"
          :center="[
            $_.get(company, 'location.point.lon', 10),
            $_.get(company, 'location.point.lat', 10),
          ]"
          :maxZoom="51"
          :dragRotate="false"
          :dragPan="false"
          :keyboard="false"
          :doubleClickZoom="false"
        >
          <mapbox-navigation-control position="top-right" />
          <mapbox-marker
            :lng-lat="[
              $_.get(company, 'location.point.lon', 10),
              $_.get(company, 'location.point.lat', 10),
            ]"
          />
        </mapbox-map>
      </b-col>
    </b-row>
  </b-container>
  -->
  <!--jimir3x-->
</template>

<script>
import USER_COMPANY from '@/graphql/user/userCompany.graphql';
import _ from 'lodash';
export default {
  data() {
    return {
      company: {
        name: '',
        website: '',
        logo: null,
        number_of_employees: {},
        description: '',
        location: {},
        media: [],
      },
      modal: {
        visible: false,
        content: {},
        index: 0
      },
    };
  },
  methods: {
    /*jimir3x*/
    onClickOverlay(e) {
      console.log("asdasd");
      if (e.target.classList.contains('gallery-modal') || 
          e.target.classList.contains('gallery-modal__inner')) {
        this.modal.visible = false;
      }
    },
    onClickOverlayCross(e) {this.modal.visible = false;},
    onClickGalleryEl(index) {
      this.modal = {
        content: this.company.media[index],
        visible: true,
        index
      }
    },
    async onClickPrev() {
      const toLeft = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            modalInner.style.transition = "unset";
            modalInner.style.display = "none";
            modalInner.style.marginLeft = "-200vw";
            modalInner.style.display = "flex";
            resolve(true);
          }, 450);
        });
      };

      const toCenter = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            modalInner.style.transition = "margin-left 450ms";
            modalInner.style.marginLeft = "0";
            resolve(true);
          }, 75);
        });
      }

      const modalInner = document.querySelector(".gallery-modal__inner");
      modalInner.style.marginLeft = "200vw";

      if( await toLeft() ) {
        const newIndex = this.modal.index - 1;
        this.modal.index = newIndex;
        this.modal.content = this.company.media[newIndex];
      }

      await toCenter();
    },
    async onClickNext() {
      const toRight = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            modalInner.style.transition = "unset";
            modalInner.style.display = "none";
            modalInner.style.marginLeft = "200vw";
            modalInner.style.display = "flex";
            resolve(true);
          }, 450);
        });
      };

      const toCenter = () => {
        return new Promise(resolve => {
          setTimeout(() => {
            modalInner.style.transition = "margin-left 450ms";
            modalInner.style.marginLeft = "0";
            resolve(true);
          }, 75);
        });
      }
      
      const modalInner = document.querySelector(".gallery-modal__inner");
      modalInner.style.marginLeft = "-200vw";

      if( await toRight() ) {
        const newIndex = this.modal.index + 1;
        this.modal.index = newIndex;
        this.modal.content = this.company.media[newIndex];
      }

      await toCenter();
      
    },
    onGalleryModalKeydown(e) {
      const {visible, index} = this.modal
      const {media} = this.company
      if(visible) {
        if(e.key == "ArrowRight" && index < media.length - 1) this.onClickNext();
        else if(e.key == "ArrowLeft" && index > 0) this.onClickPrev();
      }
    },
    /*jimir3x*/

    async get() {
      let selector = await this.$apollo.query(USER_COMPANY, {
        uid: this.$route.params.id,
      });
      
      this.company = selector('**.company');
    },
  },
  created() {
    this.get();
  },
  mounted() {
    window.addEventListener("keydown", this.onGalleryModalKeydown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onGalleryModalKeydown);
  }
};
</script>
