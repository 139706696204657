<template>
  <b-container>
    <b-row>
      <b-col
        cols="12"
        v-if="items.length > 0"
        class="request-headers-listing-tags"
      >
        <b-row class="table-columns">
          <b-col cols="3">
            <h6>{{$t("profilex.quotes.label1")}}</h6>
          </b-col>
          <b-col cols="2" style="margin-left:-7px;">
            <h6>{{$t("profilex.quotes.label2")}}</h6>
          </b-col>
          <b-col cols="2" style="margin-left: -3px;">
            <h6>
              {{$t("profilex.quotes.label3")}}
              <!-- <img src="/img/down-arrow.svg" class="img-fluid ml-2" alt="" /> -->
            </h6>
          </b-col>
          <b-col cols="2" style="margin-left: -4px;">
            <h6>
              {{$t("profilex.quotes.label4")}}
              <!-- <img src="/img/down-arrow.svg" class="img-fluid ml-2" alt="" /> -->
            </h6>
          </b-col>
          <b-col cols="2">
            <h6>
              {{$t("profilex.quotes.label5")}}
              <!-- <img src="/img/down-arrow.svg" class="img-fluid ml-2" alt="" /> -->
            </h6>
          </b-col>
          <b-col cols="1"> </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-if="items.length > 0 && loading === false">
        <div class="accordion" role="tablist" id="listViewAccordion">
          <b-card
            no-body
            class="mb-1"
            v-for="(item, index) in items"
            :key="index"
          >
            <b-card-header
              header-tag="header"
              class="p-1 list-view-headers"
              role="tab"
            >
              <b-button
                block
                v-b-toggle="'collapse' + item.id"
                variant="info"
                class="list-view-button"
                aria-expanded="false"
              >
                <b-row align-v="center" id="member-table-list">
                  <b-col cols="3">
                    <b-row>
                      <b-col cols="2">
                                                           <img
                                      :src="
                                        CDN_IMAGE_PREFIX +
                                          item.images[0] +
                                          '?size=50xauto'
                                      "
                                      class="mr-3"
                                      alt=""
                                      width="50"
                                      style="width: 50px; height: 50px; border-radius:6px"
                                    />
                      </b-col>
                      <b-col cols="10" class="pl-5">
                        <h4>{{ findSubItemByLang(item.contents).name }}</h4>
                        <h6 class="mb-0">Product ID: {{ item.product_id }}</h6>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="2">
                    <nl2br tag="h6" :text="findSubItemByLang(item.contents).description"/>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="ctr-black-color">
                        
                        {{ item.currency.symbol }} {{ item.min_price }} - {{ item.currency.symbol }} {{ item.max_price }} 
                      <span class="member-color-grey"
                        >/ {{ item.unit.shortname }}</span
                      >
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="ctr-black-color">
                      {{numberFixer(item.moq) }}
                      <span class="member-color-grey"
                        > {{ item.unit.shortname }}</span
                      >
                    </h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="ctr-black-color">
                      {{ numberFixer(item.stock) }}
                      <span class="member-color-grey"
                        > {{ item.unit.shortname }}
                      </span>
                    </h6>
                  </b-col>
                  <b-col cols="1" class="text-right">
                    <a href="javascript:void(0)" style="margin-right: 25px">
                      <img src="/img/member-accord.svg" alt="" />
                    </a>
                    <!-- <a href="">
                      <img src="/img/member-accord-edit.svg" alt="" />
                    </a> -->
                  </b-col>
                </b-row>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse' + item.id"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
              class="collapse-requests"
            >
              <b-card-body>
                <b-card-text>
                  <b-row>
                    <b-col cols="3">
                      <b-carousel
                        id="carousel-no-animation"
                        style="text-shadow: 0px 0px 2px #000"
                        no-animation
                        indicators
                        img-width="1024"
                        img-height="480"
                      >
                        <b-carousel-slide
                          v-for="(image, index) in item.images"
                          :key="index"
                          :img-src="CDN_IMAGE_PREFIX + image" 
                          style="border-radius:6px !imprtant;" 
                        ></b-carousel-slide>
                      </b-carousel>
                    </b-col>
                    <b-col cols="9">
                      <!-- <div class="member-view-close-button">
                        <a href="">{{$t("buttons.close")}}</a>
                      </div> -->
                      <b-row>
                        <b-col cols="10" class="mb-1">
                          <h1>{{ findSubItemByLang(item.contents).name }}</h1>
                          <p class="mb-0">{{$t("profilex.quotes.productid")}}: {{ item.product_id }}</p>
                          <nl2br tag="p" className="mb-35" :text="findSubItemByLang(item.contents).description"/>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="11">
                          <b-row>
                            <b-col cols="5">
                              <p class="quotations-column-content">
                               {{$t("profilex.quotes.label3")}}
                                <span
                                  >   
                                     {{ item.currency.symbol }} {{ item.min_price }} -
                                     {{ item.currency.symbol }} {{ item.max_price }}
                                     </span
                                >
                                <span class="ton-color"
                                  >/ {{ item.unit.shortname }}</span
                                >
                              </p>
                            </b-col>

                            <b-col cols="3">
                              <p class="quotations-column-content">
                               {{$t("profilex.quotes.label4")}}
                                <span>{{ numberFixer(item.moq) }} {{ item.unit.shortname }}</span>
                              </p>
                            </b-col>

                            <b-col cols="4">
                              <p class="quotations-column-content">
                                {{$t("profilex.quotes.label5")}}
                                <span>{{ numberFixer(item.stock) }} {{ item.unit.shortname }}</span>
                              </p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text>{{ text }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>

      <b-col cols="12" v-else-if="items.length === 0 && loading === false">
        <div class="text-center quotes-h3">
            <div class="dont-have-active-passive">
                <div class="row">
                    <div class="col-md-12">
                        <h6>{{$t("profilex.quotes.noquote",{name:info.name,surname:info.surname})}}</h6>
                    </div>
                    <div class="col-md-12 text-right">
                        <img src="/img/dont-have.svg" class="img-fluid" style="margin-top:250px;" alt="">
                    </div>
                </div>
            </div>
        </div>
      </b-col>
      <b-col cols="12" v-else>
          <loading :loading="loading"/>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="gridview-pagination">
        <b-col cols="12">
            <b-pagination 
                align="center"
                pills
                v-model="page"
                :total-rows="totalData"
                :per-page="limit"
                @change="changePage()"
            >
            </b-pagination>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import userProducts from "@/graphql/user/getProducts.graphql";
import _ from "lodash";
export default {
  name: "listings.vue",
  props:[
    "info",
    "search"
  ],
  data() {
    return {
      loading: false,
      limit: 100,
      cursor: null,
      filters: {
        uid: null,
      },
      items: [],
      currentPage: 1,
      totalData: null,
      selectIndex: null,
      selectItem: null,
      cursors: [],
      page: 1,
      searchOnKeyDown: null,
    };
  },
  computed: {
    totalPage() {
      let totalPage = Math.ceil(this.totalData / this.limit);
      return totalPage;
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
  },
  watch: {
      search(search) {
          this.filters.search = search;
          this.searchOnKeyDown();
      }
  },
  methods: {
    async prevPage() {
      this.loading = true;
      this.currentPage--;

      let x = this.cursors[this.cursors.length - 3];
      this.cursors.splice(this.cursors.length - 3);
      this.cursor = x;
      await this.getList();
    },
    async nextPage() {
      this.loading = true;
      this.currentPage++;

      await this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        this.items = [];
        let data = await this.$apollo.query(userProducts, {
          filters: this.filters,
          limit: this.limit,
          page: this.page,
        });

        let response = data("**.products");
        this.items = response.list;
        this.totalData = response.total;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    itemSelect(item, index) {
      this.selectItem = item;
      this.selectIndex = index;
    },
    changePage() {
        this.$nextTick()
            .then(() => this.getList())
            .then(() => this.appScrollTop());
    }
  },
  created() {
    this.searchOnKeyDown = _.debounce(() =>  this.getList(), 1000);
    let id = this.$route.params.id;
    this.filters.uid = id;
    this.getList();
  },
};
</script>
