<!--<template>
  <div class="user-profile-connections">
    <div v-if="loading" class="container">
      <loading :loading="loading" />
    </div>
    <div v-if="!loading" class="container">
      <div class="row d-flex align-items-center">
        <div v-for="item in items" class="col-4">
          <div class="card mb-4">
            <div class="card-body user-connections-item">
              <div class="user-info">
                <img
                  :src="getAvatar(item.avatar, 75)"
                  class="rounded-circle"
                  alt=""
                />
                <div class="info">
                  <a @click.prevent="getUserProfile(item.uid)" href="#" class="name">{{ item.name }} {{ item.surname }}</a>
                  <p class="title">{{ item.title.name }}</p>
                  <p class="company_name">{{ item.company.name }}</p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between">
                <div class="user-location">
                  <v-icon :icon="['fac', 'location']" />
                  <span class="location-user">{{ item.locations.find(x => x.default).address.city }}, {{ item.locations.find(x => x.default).address.country }}</span>
                </div>
                <div class="icons cursor-pointer" @click.prevent="startConversation(item.uid)">
                  <v-icon :icon="['fac', 'message']" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> -->

<template>
  <b-container class="">
    <div v-if="items.length > 0 && loading === false" class="">
        <b-row v-for="(chunk, index) in chunks" :key="index" class="mb-5">
            <b-col v-for="item in chunk" :key="item.id" cols="3">
            <div class="connections-cards shadow">
            <b-row>
                <b-col cols="12" class="left-side">
                <b-row class="member-cards-location">
                    <b-col cols="4"  >
                    <b-avatar
                        :src="getAvatar(item.avatar, 100)"
                        variant="info"
                        size="60"
                        style="margin-left:11px"
                    ></b-avatar>
                    </b-col>     

                    <b-col cols="8" class="pl-0" style="margin-left:10px">
                      <span style="text-transform: capitalize;">
                    <h3>{{ shorter(item.name, item.surname) }}</h3>
                    </span>
                    <span style="text-transform: uppercase ">
                    <p class="word-break" style="margin-top:2px" >{{  item.company.name }}</p>
                    </span>
                    <h5>
                        {{ $_.get($_.find(item.locations, x => x.default), 'address.city') || 'unnamed' }},
                        {{ $_.get($_.find(item.locations, x => x.default), 'address.country') }}
                    </h5>
                    </b-col>
                </b-row>
                <hr>
                <b-row style="display:flex; justify-content:center; margin-left:20px" >
                    <b-col cols="4">
                    <div class="profile-bottom-section">
                        <a href="#" @click.prevent="getUserProfile(item.uid)">
                            <img src="/img/user-profile.svg" class="img-fluid" alt="" />
                        </a>
                    </div>
                    </b-col>
                    <b-col cols="4">
                    <div class="profile-bottom-section">
                        <a href="#" @click.prevent="startConversation(item.uid)">
                            <img src="/img/user-chat.svg"  class="img-fluid" alt="" />
                        </a>
                    </div>
                    </b-col>
                    <b-col cols="4">
                    <div class="profile-bottom-section">
                        <a href="#" v-if="item.following == false && item.uid !== GET_USER_INFO.uid" @click.prevent="followUser(item.uid)">
                            <img src="/img/user-follow.svg" class="img-fluid" alt="" />
                        </a>
                        <a href="#" v-else>
                            <img src="/img/user-follow-disable.svg" class="img-fluid" alt="" />
                        </a>
                    </div>
                    </b-col>
                </b-row>
                </b-col>
            </b-row>
            </div>
            </b-col>
        </b-row>
        <b-row v-if="!loading" class="gridview-pagination">
            <b-col   cols="12">
                <b-pagination 
                    align="center"
                    pills
                    v-model="page"
                    :total-rows="totalData"
                    :per-page="limit"
                    @change="changePage()"
                >
                </b-pagination>
            </b-col>
        </b-row>
    </div>
    <b-row v-else-if="items.length === 0 && loading === false">
        <b-col cols="12">
            <div class="text-center quotes">
                <div class="dont-have-active-passive " style="letter-spacing:0.05;"> 
                    <div class="row">
                        <div class="col-md-12">
                        <h6>{{$t("profilex.connections.nocon",{name:info.name,surname:info.surname})}}</h6>
                        </div>
                        <div class="col-md-12 text-right ">
                            <img src="/img/dont-have.svg" class="img-fluid" style="margin-top:250px;" alt="">
                        </div>
                    </div>
                </div>
            </div>
      </b-col>
    </b-row>
    <b-row v-else>
        <b-col cols="12">
            <loading :loading="loading"/>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import getConnections from "@/graphql/user/getConnections.graphql";
import _ from 'lodash';
export default {
  name: "connections",
  props:[
    "info",
    "search"
  ],
  data() {
    return {
      loading: true,
      limit: 100,
      cursor: null,
      filters: {
        uid: null,
        type: "FOLLOWINGS",
        search: null
      },
      searchOnKeyDown: null,
      items: [],
      currentPage: 1,
      totalData: null,
      selectIndex: null,
      selectItem: null,
      cursors: [],
      page: 1
    };
  },
  computed: {
    totalPage() {
      let totalPage = Math.ceil(this.totalData / this.limit);
      return totalPage;
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
    chunks(){
        return _.chunk(this.items, 4);
    }
  },
  watch: {
      search(search) {
          this.filters.search = search;
          this.searchOnKeyDown();
      }
  },
  methods: {
    shorter(name, surname, maxLength = 15) {
      let fullname = name + " " + surname;
      if (fullname.length <= maxLength) {
        return fullname;
      }

      let names = fullname.split(" ");
      let returning = [];
      let tlength = 0;
      for (let index in names.reverse()) {
        tlength += names[index].length;
        if (tlength > maxLength) {
          returning.push(`${names[index].substr(0, 1).toUpperCase()}.`);
          continue;
        }

        returning.push(names[index]);
      }

      return returning.reverse().join(" ");
    },
    async prevPage() {
      this.loading = true;
      this.currentPage--;

      let x = this.cursors[this.cursors.length - 3];
      this.cursors.splice(this.cursors.length - 3);
      this.cursor = x;
      await this.getList();
    },
    async nextPage() {
      this.loading = true;
      this.currentPage++;

      await this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        this.items = [];
        let data = await this.$apollo.query(getConnections, {
          filters: this.filters,
          limit: this.limit,
          page: this.page,
        });
        let response = data("**.connections");
        this.$set(this, 'items', response.list);
        this.totalData = response.total;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    changePage() {
        this.$nextTick()
            .then(() => this.getList())
            .then(() => this.appScrollTop());
    },
    itemSelect(item, index) {
      this.selectItem = item;
      this.selectIndex = index;
    },

    async followUser(uid) {
        try {
            this.loading = true;
            let payload = {
                uid: uid,
            };
            let response = await this.$store.dispatch("user/followUser", {
                vm: this,
                payload,
            });
            if (response) {
                let index = _.findIndex(this.items, x => x.uid === uid);
                this.items[index].following = true;
                return this.$bvToast.toast(this.$t("Toaster.followed"), {
                   title: this.$t("Toaster.success-title"),
                    variant: "success",
                    autoHideDelay: 1350,
                });
            }
        } catch (error) {
            return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
        } finally {
            this.loading = false;
        }
    },
    async unfollowUser(uid) {
        try {
            this.loading = true;    
            let payload = {
                uid: uid,
            };
            let response = await this.$store.dispatch("user/unfollowUser", {
                vm: this,
                payload,
            });
            if (response) {
                let index = _.findIndex(this.items, x => x.uid === uid);
                this.items[index].following = false;
                return this.$bvToast.toast(this.$t("Toaster.unfollowed"), {
                    title: this.$t("Toaster.success-title"),
                    variant: "success",
                    autoHideDelay: 1350,
                });
            }
        } catch (error) {
            return this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "secondary",
          autoHideDelay: 1350,
        });
        } finally {
            this.loading = false;
        }
    },
  },
  created() {
    this.searchOnKeyDown = _.debounce(() =>  this.getList(), 1000);
    this.filters.uid = this.$route.params.id;
    this.getList();
  },
};
</script>
