<!--<template>
  <div class="user-requests-info h-100">
    <div v-if="loading" class="container">
      <div class="row">
        <div class="col-12">
          <loading :loading="loading" />
        </div>
      </div>
    </div>
    <div v-if="!loading && items.length > 0" class="container h-100">
      <div class="row justify-content-between h-100">
        <div class="col-8">
          <div class="request-info">
            <div class="card">
              <div class="card-body">
                <div class="request-info-header">
                  <div class="industry-icon">
                    <industry-icon
                      :color="'primary'"
                      :name="selectItem.industry.icon"
                    />
                  </div>
                  <div class="industry-name">
                    {{ selectItem.industry.name }}
                  </div>
                </div>
                <div class="request-info-content">
                  <h3 class="item-title">
                    {{ getDefault(selectItem.contents).name }}
                  </h3>
                  <span class="item-desc">
                    {{ getDefault(selectItem.contents).description }}
                  </span>
                  <div class="d-flex mt-5">
                    <div class="props-item">
                      <p class="props-title">Unit</p>
                      <span class="text-capitalize">{{
                        selectItem.unit.name
                      }}</span>
                    </div>
                    <div class="props-item">
                      <p class="props-title">Demand</p>
                      <span>{{ selectItem.demand }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="user-requests-list h-100">
            <div
              v-for="(item, index) in items"
              class="item"
              :class="{ active: index === selectIndex }"
              @click="itemSelect(item, index)"
            >
              <div class="item-left">
                <div class="icon">
                  <industry-icon
                    :color="'primary'"
                    :name="item.industry.icon"
                  />
                </div>
              </div>
              <div class="item-right">
                <p class="industry-name">
                  {{ item.industry.name }}
                </p>
                <div class="item-name">
                  {{ getDefault(item.contents).name }}
                </div>
                <div class="d-flex mt-1">
                  <div class="props-item">
                    <p class="props-title">Unit</p>
                    <span class="text-capitalize">{{ item.unit.name }}</span>
                  </div>
                  <div class="props-item">
                    <p class="props-title">Demand</p>
                    <span>{{ item.demand }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="loading">
              <div class="d-flex justify-content-between">
                <button
                  :disabled="isDisablePrev"
                  @click="prevPage"
                  class="btn btn-primary btn-sm m-4"
                >
                  <v-icon icon="chevron-left"></v-icon>
                </button>
                <button
                  :disabled="isDisableNext"
                  @click="nextPage"
                  class="btn btn-primary btn-sm m-4"
                >
                  <v-icon icon="chevron-right"></v-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> -->

<template>
  <b-container>
    <b-row>
      <b-col
        cols="12"
        class="request-headers-listing-tags"
        v-if="items.length > 0"
      >
        <b-row class="table-columns">
          <b-col cols="4">
            <h6>{{$t("profilex.requests.label1")}}</h6>
          </b-col>
          <b-col cols="2" class="pl-10" style="margin-left:0px">
            <h6 style="margin-left:98px">{{$t("profilex.requests.label2")}}</h6>
          </b-col>
          <b-col cols="2" class="pl-0">
            <h6 style="margin-left:137px">
              {{$t("profilex.requests.label3")}}
              <!-- <img src="/img/down-arrow.svg" class="img-fluid ml-2" alt="" /> -->
            </h6>
          </b-col>
          <b-col cols="4" class="pl-0">
            <h6 style="margin-left:133px">{{$t("profilex.requests.label4")}}</h6>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-if="items.length > 0 && loading === false">
        <div class="accordion" role="tablist" id="listViewAccordion">
          <b-card
            no-body
            class="mb-1"
            v-for="(item, index) in items"
            :key="index"
          >
            <b-card-header
              header-tag="header"
              class="p-1 list-view-headers"
              role="tab"
            >
              <b-button
                block
                v-b-toggle="'collapse' + item.id"
                variant="info"
                class="list-view-button"
                aria-expanded="false"
              >
                <b-row
                  align-v="center"
                  id="member-table-list"
                  aria-expanded="false"
                >
                  <b-col cols="4">
                    <h4 style="text-transform:capitalize; margin-left:6px">
                      {{ findSubItemByLang(item.contents).name }}</h4>
                    <nl2br tag="h6" :text="findSubItemByLang(item.contents).description"/>
                  </b-col>
                  <b-col cols="3" >
                    <h6 
                    style="margin-left:100px"
                    class="member-view-color">{{ item.unit.name }}</h6>
                  </b-col>
                  <b-col cols="2">
                    <h6 class="member-view-color">
                      {{ numberFixer(item.demand) }} <span class="flag">{{ item.unit.shortname }} </span>
                      <!-- <img
                        src="/img/down-arrow.svg"
                        class="img-fluid ml-2"
                        alt=""
                      /> -->
                    </h6>
                  </b-col>
                  <b-col cols="3">
                    <h6 class="member-view-color">
                      <!--<img src="/img/industry-icon.svg" alt="" /> -->
                      <div class="row">
                        <div class="col-md-1">
                          <industry-icon
                            :color="'primary'"
                            :name="item.industry.icon"
                          />
                        </div>
                        <div class="col-md-10">
                          {{ item.industry.name }}
                        </div>
                      </div>
                    </h6>
                  </b-col>
                  <b-col cols="1" class="text-right">
                    <a href="javascript:void(0)">
                      <img src="/img/member-accord.svg" alt="" />
                    </a>
                    <!-- <a href="">
                      <img src="/img/member-accord-edit.svg" alt="" />
                    </a> -->
                  </b-col>
                </b-row>
              </b-button>
            </b-card-header>
            <b-collapse
              :id="'collapse' + item.id"
              :visible="false"
              accordion="my-accordion"
              role="tabpanel"
              class="collapse-requests"
            >
              <b-card-body>
                <b-card-text>
                  <h1>{{ findSubItemByLang(item.contents).name }}</h1>
                  <b-row>
                    <b-col cols="8" class="mb-5">
                      <nl2br tag="p" :text="findSubItemByLang(item.contents).description"/>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <div class="row">
                        <div class="col-md-1">
                          <industry-icon
                            :color="'primary'"
                            :name="item.industry.icon"
                          />
                        </div>
                        <div class="col-md-10">
                          <p>{{ item.industry.name }}</p>
                        </div>
                      </div>
                    </b-col>

                    <b-col cols="2">
                      <p>
                        {{$t("profilex.requests.label2")}} <span>{{ item.unit.shortname }}</span>
                      </p>
                    </b-col>

                    <b-col cols="2">
                      <p>
                        {{$t("profilex.requests.label3")}} <span>{{ numberFixer(item.demand) }}</span>
                      </p>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text>{{ text }}</b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-col>

      <b-col cols="12" v-else-if="items.length === 0 && loading === false">
        <div class="text-center quotes">
            <div class="dont-have-active-passive " >
                <div class="row">
                    <div class="col-md-12">
                        <h6>{{$t("profilex.requests.norequest",{name:info.name,surname:info.surname})}}</h6>
                    </div>
                    <div class="col-md-12 text-right">
                        <img src="/img/dont-have.svg" class="img-fluid" style="margin-top:250px;" alt="">
                    </div>
                </div>
            </div>
        </div>
      </b-col>
      <b-col cols="12" v-else>
          <loading :loading="loading"/>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="gridview-pagination">
        <b-col cols="12">
            <b-pagination 
                pills
                align="center"
                v-model="page"
                :total-rows="totalData"
                :per-page="limit"
                @change="changePage()"
            >
            </b-pagination>
        </b-col>
    </b-row>
  </b-container>
</template>

<script>
import userRequests from "@/graphql/user/userRequests.graphql";
import _ from "lodash";

export default {
  name: "requests.vue",
  props:[
    "info",
    "search"
  ],
  data() {
    return {
      loading: false,
      limit: 100,
      cursor: null,
      filters: {
        uid: null,
      },
      page: 1,
      items: [],
      currentPage: 1,
      totalData: null,
      selectIndex: null,
      selectItem: null,
      cursors: [],
      searchOnKeyDown: null,
    };
  },
  computed: {
    totalPage() {
      let totalPage = Math.ceil(this.totalData / this.limit);
      return totalPage;
    },
    isDisablePrev() {
      if (this.currentPage <= 1) {
        return true;
      }
      return false;
    },
    isDisableNext() {
      if (this.currentPage >= this.totalPage) {
        return true;
      }
      return false;
    },
  },
  watch: {
      search(search) {
          this.filters.search = search;
          this.searchOnKeyDown();
      }
  },
  methods: {
    async prevPage() {
      this.loading = true;
      this.currentPage--;

      let x = this.cursors[this.cursors.length - 3];
      this.cursors.splice(this.cursors.length - 3);
      this.cursor = x;
      await this.getList();
    },
    async nextPage() {
      this.loading = true;
      this.currentPage++;

      await this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        this.items = [];
        let data = await this.$apollo.query(userRequests, {
          filters: this.filters,
          limit: this.limit,
          page: this.page,
        });
        let response = data("**.inquiries");
        this.items = response.list;
        this.totalData = response.total;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    itemSelect(item, index) {
      this.selectItem = item;
      this.selectIndex = index;
    },
    changePage() {
        this.$nextTick()
            .then(() => this.getList())
            .then(() => this.appScrollTop());
    }
  },
  created() {
    this.searchOnKeyDown = _.debounce(() =>  this.getList(), 1000);
    let id = this.$route.params.id;
    this.filters.uid = id;
    this.getList();
  },
};
</script>
