<template>
  <b-container class="info-content radius shadow">
    <b-row class="profile-x-info">
      <b-col cols="6" class="mb-5">
        <b-row>
          <b-col cols="4">
            <h4>{{$t("profilex.info.industry")}}</h4>
            <h4>{{$t("profilex.info.company")}}</h4>
            <h4>{{$t("profilex.info.usertitle")}}</h4>
            <h4 v-if="info.position">{{$t("profilex.info.position")}}</h4>
            <h4>{{$t("profilex.info.experience")}}</h4>
            
          </b-col>
          <b-col cols="8" class="info-content-section">
            <h4>{{ info.industry.name }}</h4>
            <h4>
              {{ info.company.name }}
              <img 
                v-if="$_.get(info, 'verification.icon')" 
                :src="$_.get(info, 'verification.icon')" 
                class="img-fluid" alt="" 
              />
            </h4>
            <h4>{{ info.title.name }}</h4>
            <h4 v-if="info.position">{{  info.position.name }} </h4>
            <h4 v-if="info.years_of_experience.high === '+'">{{ info.years_of_experience.low + '+' }}</h4>
            <h4 class="y-o-e" v-else >{{ info.years_of_experience.low + '-' + info.years_of_experience.high }}</h4>
            
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="6" class="interests-content-keys">
        <b-row class="mb-4">
          <b-col cols="3">
            <h4>{{$t("profilex.info.keywords")}}</h4>
          </b-col>
          <b-col cols="9" class="keywords-interest">
            <p v-for="(tag, index) in $_.slice(tags, 0, 15)" :key="index">#{{ tag }}</p>
          </b-col>
        </b-row>
      </b-col>

     
        
          <b-col class= "interests-content" cols="12">
            <h2 style="margin-left:0px" >{{$t("profilex.info.about")}}</h2>
            <nl2br tag="p" className="member-about-p" :text="info.about"/>
          </b-col>
         
         <b-col cols="12">
             <b-row style="display:flex; flex-direction: column; margin-top:40px; margin-bottom:60px;">
        <b-col cols="2">
<h4>{{$t("profilex.info.social")}}</h4>
        </b-col>
        <b-col cols="2">
         <div class="social-links" style="margin-top:30px">
                            <b-row>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['LINKEDIN']"
                                >
                                    <a :href="userSocialLinks['LINKEDIN']['link']" target="_blank">
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/card-linkedin-map.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['FACEBOOK']"
                                >
                                    <a :href="userSocialLinks['FACEBOOK']['link']" target="_blank">
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/facebook-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        size="50"
                                        alt="facebook"
                                    />
                                    
                                </div>
                                </b-col>
                                <b-col>
                                <div
                                    class="user-detail-linked-icons"
                                    v-if="userSocialLinks['TWITTER']"
                                >
                                    <a :href="userSocialLinks['TWITTER']['link']" target="_blank">
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon"
                                        alt=""
                                    />
                                    </a>
                                </div>
                                <div
                                    class="user-detail-linked-icons"
                                    v-else
                                >
                                    
                                    <img
                                        src="/img/twitter-icon.svg"
                                        class="img-fluid social-icon-disabled"
                                        alt=""
                                    />
                                   
                                </div>
                                </b-col>
                            </b-row>
                       </div>
            </b-col>
      </b-row>
        <hr style="color: #f1f1f1; margin-bottom:50px;" />
      </b-col>

    

     </b-row>

      <b-row>
      <b-col cols="2">
        <h6 class="member-view-info-header">{{$t("profilex.info.oas")}}</h6>
      </b-col>

      <b-col cols="10" class="member-view-locations">
        <a href="#" @click="selectLocation(index)" v-for="(location, index) in info.locations" :key="index"> 
            <span :class="{ 'user-profile-memberview-locations': selectedIndex === index }"> 
                {{ location.address.city || 'unnamed' }}, 
                {{ location.address.country }}  
            </span>
            <span v-if="index < info.locations.length - 1" class="walls">|</span>
        </a>
      </b-col>

      </b-row>

      <b-row>

      <b-col cols="12" class="mt-2">
        <mapbox-map
          :scrollZoom="false"
          style="height: 350px; width: 100%; margin-top: 20px"
          access-token="pk.eyJ1IjoiY2FudTkiLCJhIjoiY2tkYW5jNnZjMGpqbzM0cnhheGlhMDJjayJ9.TynY2B9VpBjKI5GOXtSNMA"
          map-style="mapbox://styles/canu9/ckdanejg91d7o1iqathzm7n5l"
          :zoom="6"
          :minZoom="1"
          :center="center"
          :maxZoom="51"
          :dragRotate="false"
          :dragPan="false"
          :keyboard="false"
          :doubleClickZoom="false"
        >
          <mapbox-navigation-control position="top-right" />
          <mapbox-marker :lng-lat="center" />
        </mapbox-map>
      </b-col>
      </b-row>

    </b-row>
  </b-container>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    info: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  name: "info",
  data() {
    return {
      center: [],
      selectedIndex: 0,
    };
  },
  computed: {
    tags() {
      return _.map(
        _.get(
          _.find(this.info.tags, (x) => x.default),
          "tags",
          []
        ),
        (x) => x.tag
      );
    },
    userSocialLinks() {
      let accounts = _.get(this, "info.social_accounts", []);
      let filtered = _.filter(accounts, (sa) => !_.isNil(sa.link));
      return _.keyBy(filtered, "type");
    },
  },
  methods: {
    selectLocation(index) {
      let point = this.info.locations[index].point;
      this.selectedIndex = index;
      this.center = [];
      this.center.push(point.lon);
      this.center.push(point.lat);
    },
  },
  created() {
    this.info.locations = _.orderBy(this.info.locations, ["default"], ["desc"]);
    let index = _.findIndex(this.info.locations, (x) => x.default);
    this.selectLocation(index);
  },
};
</script>

<style scoped></style>
